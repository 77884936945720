<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Payouts List -->
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Service Type</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                            </div>

                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <b-button size="lg" variant="primary btn-mw">Add New <i class="fa fa-plus-square"></i></b-button>
                                <!--  v-b-modal.payment_model -->
                                <button class="btn btn-outline-primary btn-lg mr-3">Download CSV <i class="fa fa-download ml-1"></i></button>
                                <button class="btn btn-outline-primary btn-lg mr-3">Upload <i class="fa fa-upload ml-1"></i></button>

                                <!-- <b-modal id="payment_model" title="Add New" hide-footer>
                                    <div class="body">
                                        <div class="modal-area">
                                            <ul class="list-unstyled mb-0 bank-model">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="bank-form" @submit.prevent="passes(payoutFormSubmit)">
                                                        <li>
                                                            <ValidationProvider name="name" rules="required" v-slot="{ errors}"> 
                                                                <div class="form-group" :class="{'errors': errors.length && !payoutoption.name,'success':payoutoption.name}">
                                                                    <span class="float-label">
                                                                        <input type="text" v-model="payoutoption.name" class="form-control" id="name" placeholder="Name*">
                                                                        <label for="name" class="control-label">Name*</label>
                                                                        <div class="line"></div>
                                                                        <i class="fa fa-times" v-if="errors.length && !payoutoption.name"></i>
                                                                        <i class="fa fa-check" v-if="payoutoption.name"></i>
                                                                    </span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </li>
                                                        <li>
                                                            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                <b-button type="submit" variant="primary btn-mw" size="lg">Add Record</b-button>
                                                                <b-button @click="$bvModal.hide('payment_model')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                            </div>
                                                        </li>
                                                    </form>
                                                </ValidationObserver>
                                            </ul>
                                        </div>
                                    </div>
                                </b-modal> -->
                            </div>
                        </div>
                        <b-table 
                            responsive
                            outlined
                            table-class="mb-0 table-custom"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.id"
                                    :title="data.value.id" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(status)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" >
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(actions)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
import '@/plugins/sweetalert2'
export default {
    name:'PayoutComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        // ValidationObserver,
        // ValidationProvider,
        // Multiselect: () => import('vue-multiselect'),
    },
    methods: {
        // Use sweetalert2
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
        
        //go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        //
        payoutFormSubmit(){
            console.log("Data submited");
        },

        // mobileFormSubmit(){
        //     console.log("mobile data submited");
        // },

        networkWithCountry ({ name, country }) {
            return `${name}- (${country}) `
        },

        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },
    },data(){
        return{
            isVisibleFilter: false,
            //items
            items:[
                {
                    uuid: {id:'SER001'},
                    servicename: 'Bank Deposit',
                    status: {id:'status_1',isChecked:true},
                    actions:'',
                },
                {
                    uuid: {id:'SER002'},
                    servicename: 'Mobile Money',
                    status: {id:'status_2',isChecked:false},
                    actions:'',
                },
                {
                    uuid: {id:'SER003'},
                    servicename: 'Airtime',
                    status: {id:'status_3',isChecked:true},
                    actions:'',
                },
            ],
           
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix' },
                { key: 'servicename', label: 'Service Name',sortable: true,thClass:'th_sortfix' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true },
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            payoutoption:{
                name: ''
            },

        }
    },mounted() {
        this.totalRows = this.items.length
    }
}
</script>
